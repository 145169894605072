<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
      :title="$t('page.otherIndividual.title')"
      @edit="$emit('edit')"
    >
      <p class="has-text-justified">
        {{ $t('page.otherIndividual.description') }}
      </p>
    </page-title>
    <div
      v-for="(individual, index) in model"
      :key="index"
      class="box"
    >
      <button
        v-if="isViewModeCreate"
        role="button"
        type="button"
        class="delete"
        tabindex="100"
        @click="removeIndividual(index)"
      >
      </button>
      <h3>{{ $t('page.otherIndividual.section') + ' ' + (index + 1) }}</h3>
      <div class="columns">
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('firstname')"
            :type="getFieldType('firstname', index)"
            :message="getFieldMessage('firstname', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.otherIndividual[${index}].firstname`)"
              v-model="individual.firstname"
              @blur="validateFirstname(index)"
              custom-class="page-focus-first"
              :name="`fname${index}`"
              autocomplete="given-name"
            >
            </b-input>
            <span v-else>{{ individual.firstname }}</span>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('lastname')"
            :type="getFieldType('lastname', index)"
            :message="getFieldMessage('lastname', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.otherIndividual[${index}].lastname`)"
              v-model="individual.lastname"
              @blur="validateLastname(index)"
              :name="`lname${index}`" 
              autocomplete="family-name"
            >
            </b-input>
            <span v-else>{{ individual.lastname }}</span>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            :label="getFieldLabel('address')"
            :type="getFieldType('address', index)"
            :message="getFieldMessage('address', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.otherIndividual[${index}].address`)"
              v-model="individual.address"
              @blur="validateAddress(index)"
              :name="`address${index}`"
              autocomplete="address-line1"
            >
            </b-input>
            <span v-else>{{ individual.address }}</span>
          </b-field>
        </div>
      </div>
      <div
        v-if="isViewModeCreate || individual.useProfessionalAddress"
        class="columns"
      >
        <div class="column">
          <b-checkbox
            v-if="isViewModeCreate"
            v-model="individual.useProfessionalAddress"
            class="mb-3"
          >
            {{ $t('page.otherIndividual.useProfessionalAddress') }}
          </b-checkbox>
          <b-field
            v-if="individual.useProfessionalAddress"
            :label="getFieldLabel('professionalAddress')"
            :type="getFieldType('professionalAddress', index)"
            :message="getFieldMessage('professionalAddress', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.otherIndividual[${index}].professionalAddress`)"
              v-model="individual.professionalAddress"
              @blur="validateProfessionalAddress(index)"
              :name="`professionalAddress${index}`"
              autocomplete="address-line1"
            >
            </b-input>
            <span v-else>{{ individual.professionalAddress }}</span>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('birthdate')"
            :type="getFieldType('birthdate', index)"
            :message="getFieldMessage('birthdate', index)"
          >
            <b-datepicker
              v-if="isFieldEditable(`$.otherIndividual[${index}].birthdate`)"
              v-model="individual.birthdate"
              :max-date="atLeastEighteenYearsOld"
              :date-formatter="formatDateWithSlashes"
              icon="calendar-day"
              trap-focus
              @blur="validateBirthdate(index)"
            >
            </b-datepicker>
            <span v-else>{{ formatBirthdate(individual.birthdate) }}</span>
          </b-field>
        </div>
      </div>
    </div>
    <b-field class="has-text-centered">
      <b-button
        v-if="isViewModeCreate && model.length < 20"
        @click="addIndividual"
        type="is-text is-anchor"
      >
        {{ $t('page.otherIndividual.add') }}
      </b-button>
    </b-field>
  </fieldset>
</template>

<script>
import page from '@/mixins/page';
import date from '@/mixins/date';

export default {
  name: 'PageOtherIndividual',
  mixins: [
    page('otherIndividual'),
    date
  ],
  methods: {
    addIndividual () {
      this.model.push({
        address: '',
        birthdate: undefined,
        firstname: '',
        lastname: '',
        professionalAddress: '',
        useProfessionalAddress: false
      });
    },
    removeIndividual (index) {
      this.model.splice(index, 1);
      this.clearErrors();
    },
    validateAddress (index) {
      this.validateNotEmpty(`address${index}`, this.model[index].address);
    },
    validateBirthdate (index) {
      if (!this.model[index].birthdate)
        this.addError(`birthdate${index}`);
      else
        this.removeError(`birthdate${index}`);
    },
    validateFirstname (index) {
      this.validateNotEmpty(`firstname${index}`, this.model[index].firstname);
    },
    validateLastname (index) {
      this.validateNotEmpty(`lastname${index}`, this.model[index].lastname);
    },
    validateProfessionalAddress (index) {
      if (this.model[index].useProfessionalAddress && this.model[index].professionalAddress.length < 1)
        this.addError(`professionalAddress${index}`);
      else
        this.removeError(`professionalAddress${index}`);
    },
    validate () {
      this.model.forEach((individual, index) => {
        this.validateAddress(index);
        this.validateBirthdate(index);
        this.validateFirstname(index);
        this.validateLastname(index);
        this.validateProfessionalAddress(index);
      });
    }
  }
};
</script>
